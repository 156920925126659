<script src="./index.js"></script>
<style src="./style.scss" lang="scss" scoped/>
<template>
  <div v-if="isLoading" class="inquiry-wrapper mt-5">
    <PagePreloader layout="list"/>
  </div>
  <div v-else>
    <div class="inquiry-wrapper" v-bind:class="{'open-filter-box': filterBoxActive}">
      <div class="add-property-title d-flex align-items-baseline ">
        <div class=""> {{ $t('vendor-products-import-title') }}</div>
      </div>
      <div class="" ref="inquiryWrapper">
        <div class="row my-3 " v-if="products">
          <div v-if="status === 3 || status === 1">
            <div class="alert alert-warning ms-0">
              <div class="d-flex">
                <div class="ms-0">
                  <div class="spinner-border spinner-border-sm text-danger" role="status">
                    <span class="sr-only"></span>
                  </div>
                </div>
                <div class="ms-2">Файлът с продукти се обработва ...</div>
              </div>
            </div>
            <div class="ms-2">Обработени <strong>{{ stats.procesed_items_count }}</strong> от
              <strong>{{ stats.products_count }}</strong> продукта
            </div>
            <div class="ms-2">Качен на: {{ stats.created_at }}</div>
          </div>
        </div>
        <div class="alert alert-warning" v-if="products && processId !== null && status === 0">
          <div class="mb-3">Моля прегледайте данните получени от файла и потвърдете, за да започне обработката му.</div>
          <div class="btn btn-success" @click="changeStatus(3)">Потвърждавам</div>
          <div class="btn ms-2 btn-danger" @click="changeStatus(5)">Отказвам</div>
        </div>
        <div class="row upload-container" v-if="!products">
          <div class="col-md-6 pe-5 d-flex justify-content-center instructions" style="">
            <div class="m-auto">
              <div>{{ $t('automated-import-of-products-step-1') }}</div>
              <div>{{ $t('automated-import-of-products-step-2') }}</div>
              <div>{{ $t('automated-import-of-products-step-3') }}</div>
              <div>{{ $t('automated-import-of-products-step-4') }}</div>
              <div>{{ $t('automated-import-of-products-step-5') }}</div>
            </div>
          </div>
          <div class="col-md-6 ps-5 upload">
            <FileInput @change="e => uploadFile(e)" :file-type="'text/tsv'"/>
            <div class="mt-2">
              <div class="d-flex">{{ $t('field-separator') }}:
                <div class="ms-auto separator-value">tab</div>
              </div>
              <div class="d-flex mt-1">{{ $t('list-field-separator') }}:
                <div class="ms-auto separator-value">;</div>
              </div>
              <div class="d-flex mt-3">{{ $t('example-file') }}:
                <div class="ms-auto"><a href="/static/docs/sakvartirantite.com-product-import-example.xlsx"
                                        target="_blank">{{ $t('download-file') }}</a></div>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-5 products-container" ref="productsContainers">
          <div class="products-wrapper">
            <div v-if="products">
              <vue3-datatable
                :rowClass="'table-row'"
                :stickyHeader="true"
                :rows="products"
                :columns="cols"
                :pageSize="100"
                :skin="'bh-table-striped'">
                <template #description="data">
                  <div style="max-height: 100px; width: 400px; overflow: auto">{{ data.value.description }}</div>
                </template>
                <template #price="data">
                  <div>{{ data.value.price }} {{ $t('bgn') }}</div>
                </template>
                <template #promoPrice="data">
                  <div v-if="data.value.promoPrice > 0">
                    {{ data.value.promoPrice }} {{ $t('bgn') }}
                  </div>
                  <div v-else>
                    <CrossIcon/>
                  </div>
                </template>
                <template #categories="data">
                  <div class="badge bg-secondary" v-for="category in data.value.categories">
                    {{ $t(category) }}
                  </div>
                </template>
                <template #images="data">
                  <div style="width: 200px; max-height: 100px; overflow: auto" v-for="image in data.value.images">
                    {{ image }}
                  </div>
                </template>
                <template #specifications="data">
                  <div style="max-height: 100px; overflow: auto">
                    <table class="table table-bordered" v-if="data.value.specifications">
                      <tr style="border-bottom: 1px solid #ccc" v-for="(value, spec) in data.value.specifications">
                        <td>{{ spec }}</td>
                        <td>{{ value }}</td>
                      </tr>
                    </table>
                  </div>
                </template>
              </vue3-datatable>
            </div>
            <div v-if="errors">
              <div class="alert alert-danger">
                Грешка! Невалиден формат на файла
              </div>
              <vue3-datatable
                :rowClass="'table-row'"
                :stickyHeader="true"
                :rows="errors"
                :columns="colsErrors"
                :pageSize="100"
                :skin="'bh-table-striped'">
                <template #description="data">
                  <div style="max-height: 100px; width: 400px; overflow: auto">{{ data.value.description }}</div>
                </template>
                <template #price="data">
                  <div>{{ data.value.price }} {{ $t('bgn') }}</div>
                </template>
                <template #promoPrice="data">
                  <div v-if="data.value.promoPrice > 0">
                    {{ data.value.promoPrice }} {{ $t('bgn') }}
                  </div>
                  <div v-else>
                    <CrossIcon/>
                  </div>
                </template>
                <template #categories="data">
                  <div class="badge bg-secondary" v-for="category in data.value.categories">
                    {{ $t(category) }}
                  </div>
                </template>
                <template #images="data">
                  <div style="width: 200px; max-height: 100px; overflow: auto" v-for="image in data.value.images">
                    {{ image }}
                  </div>
                </template>
                <template #specifications="data">
                  <div style="max-height: 100px; overflow: auto">
                    <table class="table table-bordered" v-if="data.value.specifications">
                      <tr style="border-bottom: 1px solid #ccc" v-for="(value, spec) in data.value.specifications">
                        <td>{{ spec }}</td>
                        <td>{{ value }}</td>
                      </tr>
                    </table>
                  </div>
                </template>
              </vue3-datatable>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
#automated-import {
  .modal-body {
    padding-top: 10px !important;
  }
}
</style>